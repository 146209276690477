<template>
  <v-container class="fill-height" fluid>
    <v-row justify="center">
      <v-col cols="12" sm="10" md="6" xl="4">
        <v-card class="pa-5" style="opacity: 0.88">
          <div class="title text-center">Email Verification - Terarra</div>

          <v-card-text v-if="verification == false">
            <v-form aria-autocomplete="off" autocomplete="off" @submit.prevent="register" v-on:submit.prevent>
              <h4 class="mt-5">Register with Email</h4>
              <v-text-field autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" label="Email *" name="email" prepend-icon="mdi-email" v-model="user.email" type="email" hint="Valid email account"></v-text-field>
              <div class="mt-3"></div>
              <v-text-field autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" label="Coupon Code *" name="couponCode" prepend-icon="mdi-lock" v-model="user.couponCode" type="text" hint="Enter Preview Coupon Code"></v-text-field>
            </v-form>
            <div class="m-4 red--text" v-if="errorMessage">{{ errorMessage }}</div>
            <p class="mt-3"></p>
            <v-btn color="primary" v-if="data.step == 'register'" :disabled="processing" @keyup.enter="register()" type="submit" @click="register()">{{ processing ? "Registering..." : "VERIFY EMAIL" }}</v-btn>
          </v-card-text>
          <v-card-text v-else>
            <h3 class="green--text">Thank you</h3>
            <p class="mt-3">{{ message }}</p>
          </v-card-text>
          <v-card-actions v-if="verification == false">
            <v-spacer></v-spacer>
            Already have an account? <v-btn to="/login" color="secondary" type="button" text>Login </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator"
import {cacheSrv} from "../cache/cache"
import router from "./../../router"
import {registerService} from "./register-service"
import {profileService} from "./profile-service"
import {userService} from "./user-service"
import {config, utils} from "../utils"
import axios from "axios"

@Component({
  components: {},
})
export default class Register extends Vue {
  ssoApiUrl = config.VUE_APP_SSO_API
  homeUrl = config.VUE_APP_APP_URL

  showPassword = false
  rules = {
    required: (value) => !!value || "Enter a password",
    min: (v) => v.length >= 8 || "Use 8 characters or more for your password",
    strength: (v) => {
      return this.checkPasswordStrength >= 5 || "Please choose a stronger password. Try a mix of letters, numbers, and symbols."
    },
  }

  user = {
    username: "",
    password: "",
    companyName: "",
    name: "",
    phone: "",
    email: "",
    companyAddress: "",
    confirmPassword: "",
    couponCode: "",
  }
  data: any = {
    step: "register",
  }

  verification = false
  submitted = false
  processing = false
  errorMessage = ""
  message = ""
  passwordError = false
  error = {
    msg: "",
  }

  constructor() {
    super()
  }

  async register() {
    cacheSrv.remove("profile")

    console.log("register")
    this.errorMessage = ""
    this.processing = true
    let result

    if (!this.user.email) {
      this.errorMessage = "Please provide valid email address to proceed with the registration"
      this.processing = false
      return
    }

    try {
      result = await registerService.emailVerification(this.user)
      this.processing = false
      if (result) {
        let data = result

        if (data.error) {
          this.errorPass()
          console.log("Error in registration")
          this.errorMessage = data.message
          return
        }

        if (data["verification"]) {
          this.verification = true
          this.errorMessage = ""

          this.message = "Email verification link has been sent to your email address. Please check your email and click on the link to verify your email address."
        } else {
          console.log("no verification")
          this.errorPass()
        }
      } else {
        console.log("Error in validating username. No data")
      }
    } catch (e) {
      console.log("error logging in", e)
      this.errorPass()
      this.processing = false
      this.errorMessage = "Error procesing your request. Please check your internet connection and try again"
      return
    }
  }

  async registerFull() {
    //Clean up stored profile
    cacheSrv.remove("profile")

    console.log("register")
    this.errorMessage = ""
    this.processing = true
    let result

    if (!this.user.companyName || !this.user.name || !this.user.companyAddress || !this.user.username || !this.user.password || !this.user.confirmPassword) {
      this.errorMessage = "Please fill in all fields"
      this.processing = false
      return
    }
    //add check of password and confirm password
    if (this.user.password !== this.user.confirmPassword) {
      this.errorMessage = "Password and Confirm Password do not match"
      this.processing = false
      return
    }

    try {
      result = await registerService.register(this.user)
      this.processing = false
      if (result) {
        let data = result

        if (data.error) {
          this.errorPass()
          console.log("Error in registration")
          this.errorMessage = data.message
          return
        }

        if (data["token"]) {
          this.errorMessage = ""
          await userService.initUser()
          await profileService.authorize()

          setTimeout(function () {
            router.push("/")
          }, 888)
        } else {
          console.log("no token")
          this.errorPass()
        }
      } else {
        console.log("Error in validating username. No data")
      }
    } catch (e) {
      console.log("error logging in", e)
      this.errorPass()
      this.processing = false
      this.errorMessage = "Error procesing your request. Please check your internet connection and try again"
      return
    }
  }

  errorPass() {
    this.passwordError = true
    setTimeout(() => {
      this.passwordError = false
    }, 1600)
  }

  get checkPasswordStrength() {
    let password = this.user.password
    let strength = 0
    if (password.match(/[a-z]+/)) {
      strength += 1
    }
    if (password.match(/[A-Z]+/)) {
      strength += 1
    }
    if (password.match(/[0-9]+/)) {
      strength += 1
    }
    if (password.match(/[$@#&!]+/)) {
      strength += 1
    }
    if (password.length > 8) {
      strength += 1
    }
    return strength
  }
}
</script>
